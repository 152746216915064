import { render, staticRenderFns } from "./ReservationDetailCommentsComponent.vue?vue&type=template&id=1c19dc3a&scoped=true"
import script from "./ReservationDetailCommentsComponent.vue?vue&type=script&lang=js"
export * from "./ReservationDetailCommentsComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c19dc3a",
  null
  
)

export default component.exports